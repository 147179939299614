/* Standard components */

:root {
  --white: rgb(255, 255, 255);
  --light-blue: rgb(175, 188, 194);
  --dark-blue: rgb(66, 66, 73);
  --content-width: 1170px;

  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 32px;
  --spacing-xl: 64px;

  --width-xs: 320px;
  --width-sm: 480px;
  --width-md: 768px;
  --width-lg: 1024px;
  --width-xl: 1280px;
}

body {
  background-color: var(--light-blue);
  color: var(--white);
  font-family: cursive;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

a {
  color: var(--white);
}

a.rsvp {
  display: inline-block;
  background-color: var(--dark-blue);
  border-radius: 4px;
  border: 2px solid var(--dark-blue);
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--white);
    color: var(--dark-blue);
  }

  p & {
    padding: var(--spacing-sm);
  }
}

a.courtesy-block {
  font: bold 1rem sans-serif;
  display: inline-block;
  background-color: var(--white);
  border-radius: 16px;
  border: 2px solid var(--dark-blue);
  color: var(--dark-blue);
  padding: var(--spacing-md);
  margin: var(--spacing-md) auto;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

button {
  background-color: var(--white);
  border: 2px solid var(--dark-blue);
  border-radius: 4px;
  color: var(--dark-blue);
  cursor: pointer;
  font-size: 1rem;
  margin: 10px;
  padding: 8px 64px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  width: 256px;

  &:hover {
    background-color: var(--dark-blue);
    color: var(--white);
  }
}

h1 {
  margin: 0 auto;
  padding: var(--spacing-lg) 0;
}

h2 {
  font-size: 3rem;
  margin: 0 auto;
  padding: var(--spacing-lg) 0;
}

h3 {
  font: 1rem serif;
  margin: 0 auto;
  padding: var(--spacing-sm) 0;
  text-transform: uppercase;
}

h4 {
  font: 1.2rem sans-serif;
  margin: 0 auto;
  padding: var(--spacing-md) 0;
}

/* <fieldset> Elements */

fieldset {
  border: none;
  padding: var(--spacing-md);
}

/* <inpput type="text|email|password"> Elements */

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  background-color: var(--white);
  border: 1px solid var(--dark-blue);
  border-radius: 4px;
  color: var(--dark-blue);
  font-family: serif;
  font-size: 1rem;
  margin: 0 auto var(--spacing-md);
  outline: none;
  padding: 10px;

  &:hover {
    background-color: var(--light-blue);
  }

  &:focus {
    background-color: var(--white);
    outline: none;
  }
}

/* <input type="checkbox|radio"> Elements */

input[type="checkbox"],
input[type="radio"] {
  accent-color: var(--light-blue);
  cursor: pointer;
  margin: var(--spacing-sm);
  outline: none;
}

/* <label> Elements */

label {
  font: 1rem sans-serif;
}

label:has(+ input[type="text"]),
label:has(+ input[type="email"]),
label:has(+ input[type="password"]),
label:has(+ textarea) {
  display: block;
  font-size: 1.2rem;
  margin: 0 auto var(--spacing-lg);
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  font-size: 1rem;
  margin: 0 auto var(--spacing-md);
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: var(--white);
    transition: width 0.3s ease-in-out;
  }
}
input[type="radio"]:checked + label {
  &::after {
    width: 100%;
  }
}

/* <legend> Elements */

legend {
  margin: 0;
}

/* <li> Elements */

ul li {
  list-style-type: none;
}

/* <p> Elements */

p {
  font: 1rem/1.2 serif;
  margin: 0 auto;
  max-width: 512px;
  padding: var(--spacing-sm) 0;

  &:last-child {
    margin-bottom: 0;
  }
}

/* <section> Elements */

section {
  margin: 0 auto;
  padding: var(--spacing-lg) 0;
}

/* <ul> Elements */

ul {
  padding: 0;
}

/* <TextArea> Components */

.text-area {
  margin: 0 auto var(--spacing-md);
}

/* TextField Components */

.text-field {
  margin: 0 auto var(--spacing-md);
}

/* RadioGroup Components */

.radio-group {
  display: flex;
  flex-direction: column;
}

/* Shared Header */

header {
  border-bottom: 1px solid var(--dark-blue);
  padding: var(--spacing-xl) 0;

  h1 {
    color: var(--white);
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    gap: 10px;
    letter-spacing: 5px;
    line-height: 72px;
    text-transform: none;
  }

  .and {
    font-size: 0.3em;
    line-height: 0.3em;
  }

  .date-and-location {
    font-size: 1.2rem;
    color: var(--white);
  }
}

/* Map Mat */

.embedded-map-mat {
  /* Match the width of the embedded map */
  background-color: var(--white);
  margin: var(--spacing-md) auto;
  padding: var(--spacing-sm);
  width: 402px;

  iframe {
    border: 1px solid var(--dark-blue) !important;
    display: block;
  }
}

/* Hide embedded maps on screens with width less than iframe width + mat padding */
@media screen and (max-width: 418px) {
  .embedded-map-mat {
    display: none;
  }
}

/* Home Page */

nav {
  background-color: var(--white);
  border-bottom: 1px solid var(--dark-blue);
  margin: 0 auto;
  padding: var(--spacing-md) 0;
  /*position: sticky;*/
  /*  background: linear-gradient(to bottom, var(--light-blue), 16px, var(--white)); */
  /*  margin: 0 auto var(--spacing-lg); */
  /*  padding: 0;
  /*  position: sticky;
  /*  top: 0;
  /*  width: 100%;
  /*  z-index: 1000; /* Ensures it stays above other elements */

  a {
    font-size: 1.2rem;
    padding: var(--spacing-md) var(--spacing-md);
  }

  a:not(.rsvp) {
    color: var(--dark-blue);
    text-decoration: none;
    display: inline-block;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 8px;
      width: 0;
      height: 1px;
      background-color: var(--dark-blue);
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
    }
  }
  a.rsvp {
    margin-left: var(--spacing-md);
  }
}

section.photo {
  border-bottom: 1px solid var(--dark-blue);
  padding: var(--spacing-xl) 0;

  .photo-mat {
    background-color: var(--white);
    margin: 0 auto;
    padding: 8px;
    max-width: var(--content-width);
    box-sizing: border-box;
  }

  img {
    display: block;
    height: auto;
    margin: 0;
    width: 100%;
  }
}

section#schedule {
  /*
  background: repeating-linear-gradient(
    to right,
    var(--light-blue) 0px, var(--light-blue) 10px,
    var(--white) 10px, var(--white) 20px
  );
  */
  background: var(--white);
  border-bottom: 1px solid var(--light-blue);
  color: var(--dark-blue);
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-md);

  a:not(.rsvp) {
    color: var(--dark-blue);
  }
}

section#travel {
  background: var(--dark-blue);
  border-bottom: 1px solid var(--light-blue);
  color: var(--white);
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-md);
}

section#q-and-a {
  background: var(--white);
  border-bottom: 1px solid var(--dark-blue);
  color: var(--dark-blue);
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-md);

  a {
    color: var(--dark-blue);
  }

  li {
    margin: 0 auto var(--spacing-xl);
  }
}

section#looking-forward {
  background: var(--light-blue);
  color: var(--white);
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-md);
}

/* RSVP Page */

body.rsvp {
  button {
    background-color: var(--white);
    border: 2px solid var(--white);
    color: var(--dark-blue);

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    }
  }

  h2 {
    background-color: var(--white);
    color: var(--dark-blue);
    /* To match home page navigate height. */
    line-height: 83px;
    padding: var(--spacing-sm) 0;
  }

  #root {
    padding: var(--spacing-xl) var(--spacing-md);
  }
}

section#rsvp {
  background-color: var(--dark-blue);
  padding: 0;
}

.text-field.first-and-last-name {
  input {
    width: 256px;
  }
}

fieldset.guest-rsvp {
  border: 1px solid var(--white);
  margin: 0 auto var(--spacing-xl);

  legend {
    font-size: 2rem;
    padding: 0 16px;
  }

  fieldset legend {
    font-size: 1.4rem;
  }

  p.child-note {
    background-color: var(--light-blue);
    color: var(--dark-blue);
    max-width: 1024px;
    padding: var(--spacing-md);
    margin: 0 auto var(--spacing-lg);
  }

  .text-area.dietary-restrictions {
    textarea {
      max-width: 512px;
    }

    label {
      font-size: 1.4em;
    }
  }
}

.rsvp-success-pane {
  background-color: var(--white);
  border: 1px solid;
  color: var(--dark-blue);
  margin: 0 auto;
  padding: 64px;
  max-width: 512px;
}
